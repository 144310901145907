<template>

<app-content :pending="true">

</app-content>

</template>

<script>

export default {

	created: function() {

		if (!this.$permissions.badges.overview.enabled) this.$router.push({
			name: 'Convention.Badges.Holders'
		})

	}

}

</script>

<style scoped>

</style>